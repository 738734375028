<template>
  <div class="login-container minSize">
      <!-- 头部 -->
    <div class="head">
      <div class="left">
        <div class="logo">
          <img src="@/assets/images/menu/nav_logo.svg" />
        </div>
        <div class="title">{{ $t('common.title') }}</div>
      </div>


      <div class="personal-info">
        <div class="system">
          <ul class="system-list">
            <li class="system-item">
              <meetingTopRightUploadLog />
            </li>
            <li class="system-item" @click="help">
              <my-icon class="myIcon" fontSize="16" iconName="icondaohanglan_bangzhu_bai"></my-icon>{{$t('seeting.help')}}
            </li>
            <li class="system-item" @click="service">
              <my-icon class="myIcon" fontSize="16" iconName="icondaohanglan_kefu_bai"></my-icon>{{$t('seeting.service')}}
            </li>
          </ul>
        </div>
        <Lang />
      </div>
    </div>
    <Back @handleBack="handleBack" v-show="showType!=='join'"></Back>
    <LoginMenu v-if="showType==='join'" @handleLogin="handleLogin"></LoginMenu>
    <LoginForm :isClick="$route.params.isClick" v-else></LoginForm>

    <!-- 被强制踢出会议 -->
    <el-dialog
      :title="$t('login.tips')"
      class="commonElDialog"
      :modal="false"
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="exitMeetDialog"
      width="380px"
    >
      <span>{{ exitMeetText }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="shortlyExitMeet">{{$t('login.know')}}({{ removeTime }}s)</el-button>
      </span>
    </el-dialog>


    <Footer></Footer>
  </div>
</template>


<script>
import loginMenu from "@/views/login/Login.Join.vue";
import loginForm from "@/views/login/Login.Form";
import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer"
import Back from "@/components/back/Back";
import { getServiceUrl, helpUrl} from "@/utils/tool";
import MeetingTopRightUploadLog from "../meeting/top/Meeting.Right.UploadLog.vue";
import Lang from "@/components/lang/index";

export default {
  components: {
    LoginMenu: loginMenu,
    LoginForm: loginForm,
    Header,
    Footer,
    Lang,
    MeetingTopRightUploadLog: MeetingTopRightUploadLog,
    Back
  },
  data() {
    return {
      showType: 'join',
      // 被离开会议
      removeTimer: null,
      removeTime: 3,
      exitMeetDialog: false,
      exitMeetText: "",
    }
  },
  mounted() {
    let that = this
    if(that.$route.params.isClick) {
      that.handleLogin()
    }
    
    //被结束会议弹框提示
    let isForceEndMeetToast = this.$store.state.meet.isForceEndMeetToast
    if(isForceEndMeetToast){
      this.$store.commit("meet/updateGlobalMeetState", { //返回上一页弹框提示提示
        isForceEndMeetToast: 0,
      });

      const textConfig = {
        1: this.$t('meeting.getOut'),
        2: this.$t('meeting.overTimeMeet'),
        3: this.$t('meeting.closeMeet'),
      }

      this.removeTime = 3
      this.exitMeetText = textConfig[isForceEndMeetToast]
      this.exitMeetDialog = true;

      clearInterval(this.removeTimer)
      // 开启定时器
      this.removeTimer = setInterval(() => {
        if (this.removeTime === 0) {
          this.exitMeetDialog = false;
          clearInterval(this.removeTimer)
          console.error('倒计时结束----')
        } else {
          this.removeTime = this.removeTime - 1;
        }
      }, 1000);
    }
  },
  methods: {
    handleLogin() {
      this.showType = 'login'
    },
    handleBack() {
      this.showType = 'join'
      this.$route.params.isClick = undefined
    },
    service() {
      window.open(getServiceUrl());
    },
    help() {
      window.open(helpUrl);
    },
      /**
     * 点击知道了
     */
    shortlyExitMeet() {
      clearInterval(this.removeTimer);
      this.exitMeetDialog = false
    },
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 720px;
    height: 540px;
    background: #FFFFFF;
    flex: 1;
    //box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
}


  .head {
    display: flex;
    justify-content: space-between;
    height: 56px;
    width: 100%;
    background: #1ab370;
    //box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1);
    .left {
      display: flex;
      padding-left: 40px;
      .logo {
        width: 40px;
        display: flex;
        align-items: center;

        img {
          width: 100%;
          vertical-align: middle;
          object-fit: cover;
        }
      }

      .title {
        margin-left: 10px;
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
        line-height: 56px;
      }
    }
    .personal-info {
      display: flex;
      padding-right: 40px;
      position: relative;

      .system {
        height: 100%;

        .system-list {
          display: flex;
          height: 100%;
          align-items: center;

          .system-item {
            font-size: 13px;
            font-weight: 400;
            color: #ffffff;
            line-height: 18px;
            margin-left: 24px;
            color: rgba(255, 255, 255, 0.85);
            cursor: pointer;
            &:hover,&:focus{
              color: rgba(255, 255, 255,1);
            }
            .myIcon {
              display: inline-block;
              vertical-align: middle;
              margin-right: 8px;
              opacity: 0.85;
              &:hover,&:focus{
                opacity: 1;
              }
            }
            //&:hover {
            //  color: #1AB370;
            //}
          }
        }
      }

      .avatar {
        width: 40px;
        margin-left: 40px;
        position: relative;
        padding-top: 10px;

        img {
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }


</style>
