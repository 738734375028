<template>
  <div class="header">
    <div class="logo">
      <img src="@/assets/images/menu/nav_logo.svg" />
      <span class="title">{{ $t('common.title') }}</span>
    </div>
    
    <div class="right">
      <Lang />
    </div>
  </div>
</template>

<script>
import Lang from "@/components/lang/index";

export default {
  name: 'Header',
  components: {
    Lang
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  height: 56px;
  background: #1AB370;
  width: 100%;
  padding: 0 210px;
  box-sizing: border-box;
  .logo {
    display: flex;
    align-items: center;
    .title {
      font-size: 24px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 56px;
      padding-left: 16px;
      display: block;
      width:120px;
    }
  }
  .right {
     display: flex;
  }
}
</style>
