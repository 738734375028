<template>
  <div class="login-form">
    <div class="login-form-content">
      <div class="title" v-if="loginType==='enterprise'">{{$t('login.enterprise')}}</div>
      <div class="personal" v-else>
        <div class="title">{{$t('login.self')}}</div>
        <div class="right" id="login_change_headless" @click="changeLogin">{{loginType == 'personalPwd' ? $t('login.mobilePhone'): $t('login.pws')}}</div>
      </div>
      <div class="login-item">
      </div>
      <div class="login-item email">
        <el-input
          v-if="loginType === 'enterprise'"
          v-model="loginForm.userName" 
          :placeholder="$t('login.mailbox')"
          clearable
          @input="emailInputHandle"
          id="login_mail_input_headless"
        ></el-input>
        <el-input
          v-else-if = "loginType === 'personalPwd'"
          v-model="loginForm.userName" 
          :placeholder="$t('login.phone')"
          clearable
          @input="mobileInputHandle"
          id="login_mobile_input_headless"
        ></el-input>
          <el-input
          v-else
          v-model="loginForm.userName" 
          :placeholder="$t('login.phone')"
          clearable
          @input="codeInputHandle"
          id="login_code_input_headless"
        ></el-input>
      </div>
      <div class="login-item password">
        <!-- 企业密码 -->
        <el-input
          v-if="loginType!=='personalCode'?true:false"
          :placeholder="$t('login.inputPwsd')"
          type="password"
          auto-complete="new-password"
          v-model.trim="loginForm.password"
          :maxlength="40"
          clearable
          show-password
          id="login_pwd_input_headless"
        ></el-input>
        <el-input 
          v-else
          v-model="loginForm.code" 
          v-show="loginType==='personalCode'?true:false"
          @input="codeOnlyNumber"
          :placeholder="$t('login.inputCode')"
          :maxlength="4"
          auto-complete="new-password"
          clearable
          id="login_newpwd_input_headless"
        ></el-input>
        <el-button id="login_code_btn_headless" :class="codeDisabled ? 'disabled_style' : 'btn_style'" v-if="loginType==='personalCode'?true:false" type="primary" @click="sendcodeHandle" :disabled="codeDisabled">
         {{btnText}}
        </el-button>
      </div>

      <div class="login-button common-button">
        <el-button id="login_submit_btn_headless" :loading="loginLoading" type="primary" :disabled="isDisableLoginBtn()" @click="handleLogin">{{ loginLoading ? $t('home.signing') : $t('home.signIn')}}</el-button>
      </div>

      <div class="help-info">
        <span @click="handlePersonal" id="login_type_headless">{{ loginType === 'enterprise' ? $t('login.self'):$t('login.enterprise') }}</span>
        <span v-if="loginType === 'enterprise' ? false : true" @click="onForgotPwd">{{$t('login.findPwsd')}}</span>
      </div>
      <div class="register-info">
        {{$t('login.noAccount')}}
        <span @click="handleRegister">{{$t('login.register')}}</span>
      </div>

      <!-- 通用弹窗 -->
      <el-dialog
        custom-class="commonElDialog"
        width="380px"
        :visible.sync="commonElDialog"
      >

        <div class="dialog-title">
          <div class="icon error"></div>
          <div class="text">{{ commonElDialogTitle }}</div>
        </div>

        <div class="dialog-content">
          {{ commonElDialogText }}
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="commonElDialog = false">{{$t('login.know')}}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import CryptoJS from "crypto-js";
import { setToken, setRefreshToken, setClientId, setClientSecret, setMobile, getPhone, setEmail, getMobile, setPhone, getEmail} from "@/utils/auth";
import { checkMobile, checkCode, checkEmail } from "@/utils/check";
import debounce from 'lodash.debounce'
import { exitEnterprise, exitPermituser, sendCode, oauthLogin } from '@/api/meet'
import throttle from 'lodash.throttle'
import { THROTLE_TIME } from "@/constant/index";

const maxCodeTime = 60

export default {
  name: "loginForm",
  destroyed() {
    clearInterval(this.codeTimer)

    this.removeKeyDownHandle()
  },

  props: {
    isClick: { default: false },
  },

  data() {
    return {
      commonElDialog: false,
      commonElDialogTitle: '',
      commonElDialogText: '',

      loginType: "enterprise", // 默认企业登录

      loginForm: {
        userName: '',
        password: '',
        code: ''
      },

      btnText: this.$t('login.getCode'),
      
      // 验证码相关
      codeTimer: null,
      codeTime: maxCodeTime,
      codeDisabled: false,
      loginLoading: false
    }
  },

  created() {
    this.initKeyDownHandle()

    if (this.isClick) {
      this.handlePersonal()
    }
  },

  watch: {
    loginType: {
      handler(newVal) {
        if (newVal === 'enterprise') { 
          this.loginForm.userName = getEmail() || ''// 企业登录
        } else if(newVal === 'personalPwd'){
          this.loginForm.userName = getMobile() || getPhone()//个人手机号登录
        }else if(newVal === 'personalCode') {
          this.loginForm.userName = getPhone() || getMobile()//个人验证码登录
        }
      },
      immediate: true
    }
  },
  methods: {
    emailInputHandle: debounce(function(e) {
      e && setEmail(e)
    }, 1000),
    mobileInputHandle: debounce(function(e) {
      e && setMobile(e)
    }, 1000),
    codeInputHandle: debounce(function(e) {
      e && setPhone(e)
    }, 1000),
    keydownHandle(e) {
      if (e.keyCode == 13) {
        if(this.$store.state.meet.meetDialogInfo) {
          this.$store.commit("meet/updateGlobalMeetState", {
            meetDialogInfo: null
          })
          return
        }

        const { userName, password, code } = this.loginForm 

        if (this.loginType === 'personalCode') { // 验证码登录
          userName && code && this.handleLogin()
        } else { // 账号密码登录
          userName && password && this.handleLogin()
        }
      }
    },

    initKeyDownHandle() {
      window.addEventListener('keydown', this.keydownHandle)
    },
    removeKeyDownHandle() {
      window.removeEventListener('keydown', this.keydownHandle)
    },

    isDisableLoginBtn() {
      if (this.loginType === 'personalCode') {
        return !(this.loginForm.userName && this.loginForm.code)
      } else {
        return !(this.loginForm.userName && this.loginForm.password)
      }
    },

    codeOnlyNumber(value) {
      this.loginForm.code = value.replace(/[^\d]/g, ""); 
    },

    mobileOnlyNumber(value) {
      this.loginForm.userName = value.replace(/[^\d]/g, ""); 
    },

    // 跳转注册页面
    handleRegister() {
      this.$router.push({ path: "register" });
    },
    // 跳转忘记密码页面
    onForgotPwd() {
      this.$router.push({ path: "forgotPwd" });
    },

    // 切换密码登陆和手机验证码登录
    changeLogin() {
      if (this.loginType === "personalPwd") {
        //personalPwd个人密码登录
        this.loginType = "personalCode";
      } else {
        //personalCode个人手机验证码
        this.loginType = "personalPwd";
      }
    },
   // 企业与个人切换按钮
   handlePersonal() {
      // enterprise 企业, personalPwd密码, personalCode个人手机验证码
      if (this.loginType === "enterprise") {
        this.loginType = "personalPwd";
      } else {
        this.loginType = "enterprise";
      }
      this.loginForm.password = ''
    },

    async sendcodeHandle() { // 点击发送验证码
      if (!checkMobile(this.loginForm.userName)) {
        this.commonElDialogText = this.$t('login.try')
        this.commonElDialogTitle = this.$t('login.faildCode')
        this.commonElDialog = true
        return
      }

      try {
        this.codeDisabled = true

        await sendCode({
          phone: this.loginForm.userName
        })

        this.$message({
          message: this.$t('login.successCode'),
          type: 'success',
          customClass: 'send-msg-tip'
        });

        this.codeTimer = setInterval(() => {
          if (this.codeTime === 0) {
            clearInterval(this.codeTimer)

            this.codeTime = maxCodeTime
            this.codeDisabled = false
            this.btnText = this.$t('login.getCode');

          } else {
            this.codeTime = this.codeTime - 1
            this.btnText =  this.codeTime +  this.$t('login.resend');
          }
        }, 1000)
      } catch (error) {
        this.codeDisabled = false
      }
    },

    async remoteExistAccount(userName) {
      try {
        let fetchApi = () => {}
        if (this.loginType === "enterprise") {
          fetchApi = exitEnterprise
        } else {
          fetchApi = exitPermituser
        }

        const result = await fetchApi({
          userName
        })

        return result
      } catch (error) {
        return true
      }

      //  const res = await this.$get({ action: url }).catch(error => {
      //    if (error.context) { // TODO:
      //     error.context.commonElDialogTitle =  this.$t('login.loginFailed');
      //    }
      //  });
    },


    // 点击登录
    handleLogin: throttle(async function() {
      let that = this;

      if (that.loginType === "enterprise") { // 企业邮箱
        if (!checkEmail(this.loginForm.userName)) {
          that.commonElDialogTitle = this.$t('login.tips')
          that.commonElDialogText = this.$t('login.failEmail')
          that.commonElDialog = true
          return
        }
      } else { // 手机号
        if (!checkMobile(this.loginForm.userName)) { // 验证手机号
          that.commonElDialogTitle = this.$t('login.tips')
          that.commonElDialogText = this.$t('login.try')
          that.commonElDialog = true

          return
        }

        if (that.loginType === "personalCode" && !checkCode(this.loginForm.code)) {
          that.commonElDialogTitle = this.$t('login.tips')
          that.commonElDialogText = this.$t('login.failTextCode')
          that.commonElDialog = true

          return
        }
      }

      if (that.loginType !== "personalCode") {
          //服务端验证 企业账户名、手机号唯一性
          const isRemoteExist = await this.remoteExistAccount(this.loginForm.userName)

          if (!isRemoteExist) {
            this.commonElDialogTitle = this.$t('login.tips')
            this.commonElDialogText = `${that.loginType === "enterprise" ? this.$t('login.enterpriseMailbox') : this.$t('login.phone')}` + this.$t('login.notRegistered');
            this.commonElDialog = true

            return;
          }
      }

      let data = {};
      if (that.loginType === "enterprise") {
        let password = Base64.stringify(
          CryptoJS.enc.Utf8.parse(sha256(this.loginForm.password).toString())
        );
        data.password = password;
        data.username = this.loginForm.userName
        data.grant_type = "mix";
        data.client_id = "yb_meeting_client";
        data.client_secret = "123456";
      } else if (that.loginType === "personalCode") {
        data.username = this.loginForm.userName
        data.grant_type = "sms";
        data.client_id = "client_id";
        data.client_secret = "client_secret";
        data.checkCode = this.loginForm.code
      } else if (that.loginType === "personalPwd") {
        let password = Base64.stringify(
          CryptoJS.enc.Utf8.parse(sha256(this.loginForm.password).toString())
        );
        data.password = password;
        data.username = this.loginForm.userName
        data.grant_type = "password";
        data.client_id = "client_id";
        data.client_secret = "client_secret";
      }

      try {
        this.loginLoading = true
        const { accessToken, refreshToken } = await oauthLogin(data)
        this.loginLoading = false
        setToken(accessToken)
        setRefreshToken(refreshToken)
        setClientId(data.client_id)
        setClientSecret(data.client_secret)

        this.$router.push({ name: "index" })
      } catch (error) {
        this.loginLoading = false
        console.log(error) // TODO:

        // if (error.context) {
        //   error.context.commonElDialogTitle = this.$t('login.loginFailed')
        // }
      }
    }, THROTLE_TIME,{ leading: true, trailing: false})
  },
};
</script>

<style scoped lang="scss">
  ::v-deep .el-input__inner{
      padding:0;
      border:none;
      border-radius: 0;
      border-bottom: 1px solid #dcdfe6;
      color: #333333;
    }
.login-form {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  flex: 1 1 auto;
  //box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  .login-form-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    ::v-deep .el-input__inner::placeholder{
      color: #999999;
      font-size: 14px;
    }
    .title {
      display: inline-block;
      height: 33px;
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      line-height: 33px;
    }

    .personal {
      width: 360px;

      .title {
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        line-height: 33px;
      }
      .right {
         float: right;
      }

      .personal-change {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        cursor: pointer;
      }
    }

    .login-item {
      width: 360px;
      height: 40px;
      position: relative;
      ::v-deep .input-text-parent .eyes{
          right: 32px;
        }
        ::v-deep .input-text-parent .input-box{
           padding: 0 20px 0 4px;
        }
      ::v-deep .yb-button{
          width:114px;
          height:30px;
          margin-bottom:5px;
        }
      input {
        width: 100%;
        height: 100%;
        border: none;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      input:focus {
        outline: none;
        border-color: #1ab370;
      }
    }

    .email {
      input {
        border-bottom: 1px solid #e5e5e5;
      }
    }

    .password {
      margin-top: 16px;
      input {
        border-bottom: 1px solid #e5e5e5;
        position: relative;
      }
      ::v-deep .el-button{
        padding:0;
      }
      @mixin public-code(){
        position: absolute;
        right: 0;
        bottom: 4px;
        height: 30px;
        border-radius: 4px;
        padding: 6px 10px;
        font-family: PigFangSC-Regular, PingFang SC;
        text-align: center;
        line-height: 10px;
        font-weight: 400;
      }
      .btn_style{
        min-width: 90px;
        width: auto;
        background: rgba(255, 255, 255);
        border: 1px solid #FF8200;
        color:#FF8200;
        @include public-code;
        &:focus{
          background: rgba(255, 130, 0, 0.1);
        }
        &:hover{
          background: rgba(255, 130, 0, 0.2);
        }
      }
      .disabled_style{
        border:none;
        background: #FFDFA3;
        color: #FFFFFF;
        @include public-code;
      }
    }

    .login-button {
      width: 360px;
      height: 40px;
      margin-top: 40px;

      button {
        width: 100%;
      }
    }

    .help-info {
      width: 360px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 16px;

      span {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        cursor: pointer;
        user-select: none;
        &:focus,&:hover{
          color:#1AB370;
        }
      }
    }

    .register-info {
      margin-top: 50px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
      user-select: none;

      span {
        color: #1ab370;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}

.right {
  display: inline-block;
  // width: 98px;
  height: 33px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 33px;
  cursor: pointer;
  &:focus,&:hover{
    color:#1AB370;
  }
}

</style>
<style lang="css">
 .send-msg-tip.el-message--success{
    min-width: auto;
    border-radius: 40px;
    background-color: #fff;
    top: 100px !important;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 15px;
  }
  .send-msg-tip.el-message .el-icon-success{
    color: #1AB370;
    font-size: 18px;
  }
  .send-msg-tip .el-message__content{
    color: #333333 !important;
  }
</style>
